import React, { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { TextField, Button, Card, CardContent, Typography } from '@mui/material';

const Login = ({ theme }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();

    const handleSubmit = e => {
        e.preventDefault();
        login({ username, password }).catch(() =>
            notify('Invalid username or password')
        );
    };

    return (
        <form onSubmit={handleSubmit}>
            <Card>
                <CardContent>
                    <Typography variant="h5">Admin Login</Typography>
                    <TextField
                        name="username"
                        type="text"
                        label="Username"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="password"
                        type="password"
                        label="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button variant="contained" type="submit" color="primary" fullWidth>
                        Login
                    </Button>
                </CardContent>
            </Card>
        </form>
    );
};

export default Login;