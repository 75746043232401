import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  DateTimeInput,
} from 'react-admin';
import { Card, CardContent, Typography, Grid } from '@mui/material';

const weekdays = [
  { id: 0, name: 'Sunday' },
  { id: 1, name: 'Monday' },
  { id: 2, name: 'Tuesday' },
  { id: 3, name: 'Wednesday' },
  { id: 4, name: 'Thursday' },
  { id: 5, name: 'Friday' },
  { id: 6, name: 'Saturday' },
];

export const LotteryList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="state" />
      <TextField source="country" />
    </Datagrid>
  </List>
);

const LotteryScheduleForm = () => (
  <SimpleFormIterator>
    <TextInput source="name" />
    <NumberInput source="time" step={0.01} />
    <NumberInput source="max_bet_time" step={0.01} />
    <DateTimeInput source="time_with_tz" />
    <Card>
      <CardContent>
        <Typography variant="h6">Weekdays</Typography>
        <Grid container spacing={2}>
          {weekdays.map((day) => (
            <Grid item xs={3} key={day.id}>
              <BooleanInput source={`weekdays.${day.id}`} label={day.name} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  </SimpleFormIterator>
);

const LotteryForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="name" />
    <ReferenceInput source="state_id" reference="lottery_states">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="country" defaultValue="Brazil" />
    <ArrayInput source="lottery_schedules">
      <LotteryScheduleForm />
    </ArrayInput>
  </SimpleForm>
);

export const LotteryEdit = (props) => (
  <Edit {...props}>
    <LotteryForm />
  </Edit>
);

export const LotteryCreate = (props) => (
  <Create {...props}>
    <LotteryForm />
  </Create>
);