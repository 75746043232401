import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  Filter,
  useRecordContext,
} from 'react-admin';
import { Card, CardContent, Typography, Grid, List as MUIList, ListItem, ListItemText } from '@mui/material';

const WithdrawalFilter = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <SelectInput 
        source="status" 
        choices={[
          { id: 'pending', name: 'Pending' },
          { id: 'approved', name: 'Approved' },
          { id: 'rejected', name: 'Rejected' },
          { id: 'cancelled', name: 'Cancelled' },
        ]} 
        alwaysOn
      />
    </Filter>
  );

  export const WithdrawalList = (props) => (
    <List {...props} filters={<WithdrawalFilter />}>
      <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="user_id" />
      <TextField source="type" />
      <TextField source="status" />
      <TextField source="method" />
      <NumberField source="amount" />
      <TextField source="withdrawal_type" />
      <TextField source="key" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

const LedgerSummary = () => {
  const record = useRecordContext();
  const { ledgerSummary } = record;

  if (!ledgerSummary) return null;

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Ledger Summary</Typography>
        <Typography>Current Balance: ${ledgerSummary.currentBalance.toFixed(2)}</Typography>
        <Typography>Total Deposits: ${ledgerSummary.totalDeposits.toFixed(2)}</Typography>
        <Typography>Total Withdrawals: ${ledgerSummary.totalWithdrawals.toFixed(2)}</Typography>
        <Typography variant="h6" style={{ marginTop: '1rem' }}>Recent Transactions</Typography>
        <MUIList>
          {ledgerSummary.recentTransactions.map((transaction, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={`$${transaction.amount.toFixed(2)}`}
                secondary={new Date(transaction.created_at).toLocaleString()}
              />
            </ListItem>
          ))}
        </MUIList>
      </CardContent>
    </Card>
  );
};

export const WithdrawalEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput disabled source="id" />
          <TextInput disabled source="user_id" />
          <TextInput disabled source="user_phone" label="User Phone" />
          <TextInput disabled source="type" />
          <SelectInput source="status" choices={[
            { id: 'pending', name: 'Pending' },
            { id: 'approved', name: 'Approved' },
            { id: 'rejected', name: 'Rejected' },
            { id: 'cancelled', name: 'Cancelled' },
          ]} />
          <TextInput disabled source="method" />
          <NumberInput disabled source="amount" />
          <TextInput disabled source="withdrawal_type" />
          <TextInput disabled source="key" />
          <DateField source="created_at" />
          <DateField source="updated_at" />
          <TextInput multiline source="admin_notes" label="Admin Notes" />
        </Grid>
        <Grid item xs={12} md={6}>
          <LedgerSummary />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);