import React, { useState, useEffect, useCallback } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Edit,
  Create,
  SimpleForm,
  DateInput,
  ReferenceInput,
  SelectInput,
  useRecordContext,
  useDataProvider,
  required
} from 'react-admin';
import PlacementInput from '../components/PlacementInput';  // adjust the import path as needed


const formatTime = (time) => {
    if (typeof time === 'number') {
      const hours = Math.floor(time);
      const minutes = Math.round((time - hours) * 60);
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }
    if (time instanceof Date) {
      return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    return time;  // If it's already a formatted string
  };

const LotteryNameField = ({ source }) => {
  return <TextField source={`${source}.name`} />;
};

const LotteryScheduleField = ({ source }) => {
    const record = useRecordContext();
    const formatTime = (time) => {
      if (typeof time === 'number') {
        const hours = Math.floor(time);
        const minutes = Math.round((time - hours) * 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      }
      if (time instanceof Date) {
        return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      }
      if (typeof time === 'string') {
        return time.split('+')[0]; // Remove timezone if present
      }
      return time;
    };
  
    if (!record || !record.lottery_schedule) return null;
  
    const { name, time, time_with_tz } = record.lottery_schedule;
    const formattedTime = formatTime(time || time_with_tz);
  
    return <span>{`${name} - ${formattedTime}`}</span>;
  };
  export const LotteryResultList = (props) => (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <LotteryNameField source="lottery" />
        <LotteryScheduleField source="lottery_schedule" />
        <DateField source="date" />
        <TextField source="placement" />
      </Datagrid>
    </List>
  );


  const LotteryResultForm = (props) => {
    const record = useRecordContext();
    const [date, setDate] = useState(null);
    const [lotteryId, setLotteryId] = useState('');
    const [schedules, setSchedules] = useState([]);
    const dataProvider = useDataProvider();
  
    useEffect(() => {
      if (record) {
        setDate(record.date || null);
        setLotteryId(record.lottery_id || '');
      }
    }, [record]);
  
    const fetchSchedules = useCallback(async () => {
        if (lotteryId && date) {
          try {
            console.log('Fetching schedules for lottery_id:', lotteryId);
            const { data } = await dataProvider.getList('lottery_schedules', {
              pagination: { page: 1, perPage: 100 },
              sort: { field: 'time', order: 'ASC' },
              filter: { lottery_id: lotteryId },
            });
      
            console.log('Fetched schedules:', data);
      
            // Create a date object in UTC
            const selectedDate = new Date(date + 'T00:00:00Z');
            const dayOfWeek = selectedDate.getUTCDay();
      
            console.log('Selected date:', selectedDate.toUTCString());
            console.log('Day of week:', dayOfWeek);
      
            const availableSchedules = data.filter(schedule => {
              console.log('Schedule:', schedule);
              const weekdays = Array.isArray(schedule.weekdays) 
                ? schedule.weekdays 
                : JSON.parse(schedule.weekdays);
              console.log('Weekdays:', weekdays);
              return weekdays.includes(dayOfWeek);
            }).map(schedule => ({
              ...schedule,
              name: `${schedule.name} - ${formatTime(schedule.time || schedule.time_with_tz)}`
            }));
      
            console.log('Available schedules:', availableSchedules);
      
            setSchedules(availableSchedules);
          } catch (error) {
            console.error('Error fetching schedules:', error);
          }
        } else {
          setSchedules([]);
        }
      }, [lotteryId, date, dataProvider]);

    useEffect(() => {
      fetchSchedules();
    }, [fetchSchedules]);
  
    return (
      <SimpleForm {...props}>

        <ReferenceInput 
          source="lottery_id" 
          reference="lotteries"
        >
          <SelectInput 
            optionText="name" 
            validate={[required()]}
            onChange={(event) => {
              console.log('Lottery changed:', event.target.value);
              setLotteryId(event.target.value);
            }}
          />
        </ReferenceInput>
        <DateInput 
          source="date" 
          validate={[required()]}
          onChange={(event) => {
            console.log('Date changed:', event.target.value);
            setDate(event.target.value);
          }}
        />
        <SelectInput
          source="lottery_schedule_id"
          choices={schedules}
          optionText="name"
          optionValue="id"
          disabled={!lotteryId || !date || schedules.length === 0}
          validate={[required()]}
        />
        <PlacementInput source="placement" validate={[required()]} />
      </SimpleForm>
    );
  };


  
export const LotteryResultEdit = (props) => (
  <Edit {...props}>
    <LotteryResultForm />
  </Edit>
);

export const LotteryResultCreate = (props) => (
  <Create {...props}>
    <LotteryResultForm />
  </Create>
);