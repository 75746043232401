import React from 'react';
import { Admin, Resource, Layout } from 'react-admin';
import supabaseDataProvider from './providers/supabaseDataProvider';
import authProvider from './providers/authProvider';
import { WithdrawalList, WithdrawalEdit } from './resources/Withdrawals';
import { LotteryList, LotteryEdit, LotteryCreate } from './resources/Lotteries';
import { LotteryResultList, LotteryResultEdit, LotteryResultCreate } from './resources/LotteryResults';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { Menu } from './components/Menu';

const CustomLayout = (props) => <Layout {...props} menu={Menu} />;

const App = () => (
  <Admin 
    dataProvider={supabaseDataProvider}
    authProvider={authProvider}
    loginPage={Login}
    dashboard={Dashboard}
    layout={CustomLayout}
  >
    <Resource name="withdrawals" list={WithdrawalList} edit={WithdrawalEdit} />
    <Resource name="lotteries" list={LotteryList} edit={LotteryEdit} create={LotteryCreate} />
    <Resource name="lottery_results" list={LotteryResultList} edit={LotteryResultEdit} create={LotteryResultCreate} />
  </Admin>
);

export default App;