import React, { useState, useEffect } from 'react';
import { useRecordContext } from 'react-admin';
import { Grid, TextField as MUITextField } from '@mui/material';

const PlacementInput = ({ source }) => {
  const record = useRecordContext();
  const [placements, setPlacements] = useState(Array(7).fill(''));

  useEffect(() => {
    const initialValue = record?.[source] || '';
    if (initialValue) {
      let parsedPlacements;
      if (typeof initialValue === 'string') {
        try {
          parsedPlacements = JSON.parse(initialValue);
        } catch (e) {
          parsedPlacements = initialValue.split(',');
        }
      } else if (Array.isArray(initialValue)) {
        parsedPlacements = initialValue;
      }
      
      if (Array.isArray(parsedPlacements)) {
        setPlacements(parsedPlacements.map(p => p.toString()));
      } else {
        setPlacements(Array(7).fill(''));
      }
    }
  }, [record, source]);

  const handlePlacementChange = (index, newValue) => {
    const newPlacements = [...placements];
    newPlacements[index] = newValue;
    setPlacements(newPlacements);
    // You might want to update the record here if needed
  };

  return (
    <Grid container spacing={2}>
      {placements.map((placement, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <MUITextField
            label={`Placement ${index + 1}`}
            value={placement}
            onChange={(e) => handlePlacementChange(index, e.target.value)}
            fullWidth
            margin="normal"
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PlacementInput;