// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { Title } from 'react-admin';
import axios from 'axios';

const Dashboard = () => {
  const [balance, setBalance] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await axios.get('https://server.trevodasorteonline.com/proxy', {
          headers: {
            'X-Target-URL': 'https://api.brasilcash.com.br/banking/v1/account/me',
            'Content-Type': 'application/json',
          },
        });
        setBalance(response.data.balance.available / 100);
        setError(null);
      } catch (error) {
        console.error('Error fetching balance:', error);
        let errorMessage = 'Failed to fetch balance: ';
        if (error.response) {
          console.error('Error data:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
          errorMessage += `Status ${error.response.status}`;
        } else if (error.request) {
          console.error('Error request:', error.request);
          errorMessage += 'No response received';
        } else {
          console.error('Error message:', error.message);
          errorMessage += error.message;
        }
        setError(errorMessage);
        setBalance(null);
      }
    };
  
    fetchBalance();
  }, []);

  return (
    <div>
      <Title title="Dashboard" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Current Bank Balance
              </Typography>
              <Typography variant="h4" component="div">
                {balance !== null
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(balance)
                  : 'Loading...'}
              </Typography>
              {error && (
                <Typography color="error">
                  {error}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;