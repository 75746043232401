import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseSecretKey = process.env.REACT_APP_SUPABASE_SECRET_KEY;

const supabase = createClient(supabaseUrl, supabaseSecretKey);

const fetchUserPhone = async (userId) => {
  const { data, error } = await supabase
    .from('auth.users')
    .select('phone')
    .eq('id', userId)
    .single();

  if (error) {
    console.error('Error fetching user phone:', error);
    return null;
  }

  return data?.phone;
};

const fetchLedgerSummary = async (userId) => {
  const { data, error } = await supabase
    .from('ledger_entries')
    .select('amount, balance_after, created_at')
    .eq('user_id', userId)
    .order('created_at', { ascending: false })
    .limit(10);

  if (error) {
    console.error('Error fetching ledger summary:', error);
    return null;
  }

  const latestBalance = data[0]?.balance_after || 0;
  const totalDeposits = data.reduce((sum, entry) => entry.amount > 0 ? sum + entry.amount : sum, 0);
  const totalWithdrawals = data.reduce((sum, entry) => entry.amount < 0 ? sum + Math.abs(entry.amount) : sum, 0);

  return {
    currentBalance: latestBalance,
    totalDeposits,
    totalWithdrawals,
    recentTransactions: data
  };
};

const supabaseDataProvider = {
  getList: async (resource, params) => {
    console.log('getList called for resource:', resource, 'with params:', params);
    if (resource === 'lottery_results') {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const { filter } = params;
    
      let query = supabase
        .from(resource)
        .select(`
          *,
          lottery:lottery_id (name),
          lottery_schedule:lottery_schedule_id (id, name, time, time_with_tz)
        `, { count: 'exact' });
    
      // Apply filters
      Object.keys(filter).forEach(key => {
        query = query.eq(key, filter[key]);
      });
    
      // Apply sorting
      query = query.order(field, { ascending: order === 'ASC' });
    
      // Apply pagination
      const { data, error, count } = await query
        .range((page - 1) * perPage, page * perPage - 1);
    
      if (error) throw error;
    
      return {
        data,
        total: count,
      };
    }
    if (resource === 'lottery_schedules') {
      const { filter } = params;
    
      let query = supabase
        .from(resource)
        .select('*');
    
      // Apply filters
      Object.keys(filter).forEach(key => {
        query = query.eq(key, filter[key]);
      });
    
      const { data, error, count } = await query;
    
      if (error) {
        console.error('Error fetching lottery schedules:', error);
        throw error;
      }
    
      console.log('Fetched lottery schedules:', data);
    
      return {
        data,
        total: count,
      };
    }
    if (resource === 'lottery_results') {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const { filter } = params;
    
      let query = supabase
        .from(resource)
        .select(`
          *,
          lottery:lottery_id (name),
          lottery_schedule:lottery_schedule_id (name)
        `, { count: 'exact' });
    
      // Apply filters
      Object.keys(filter).forEach(key => {
        query = query.eq(key, filter[key]);
      });
    
      // Apply sorting
      query = query.order(field, { ascending: order === 'ASC' });
    
      // Apply pagination
      const { data, error, count } = await query
        .range((page - 1) * perPage, page * perPage - 1);
    
      if (error) throw error;
    
      return {
        data,
        total: count,
      };
    }
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { filter } = params;
  
    let query = supabase
      .from(resource)
      .select('*', { count: 'exact' });
  
    // Apply filters
    Object.keys(filter).forEach(key => {
      if (key === 'q') {
        // Assuming 'phone' is a searchable column
        query = query.ilike('user_phone', `%${filter[key]}%`);
      } else {
        query = query.eq(key, filter[key]);
      }
    });
  
    // Apply sorting
    query = query.order(field, { ascending: order === 'ASC' });
  
    // Apply pagination
    const { data, error, count } = await query
      .range((page - 1) * perPage, page * perPage - 1);
  
    if (error) throw error;
  
    return {
      data,
      total: count,
    };
  },
  
  getOne: async (resource, params) => {
    if (resource === 'lottery_results') {
      const { data, error } = await supabase
        .from(resource)
        .select(`
          *,
          lottery:lottery_id (name),
          lottery_schedule:lottery_schedule_id (id, name, time, time_with_tz)
        `)
        .eq('id', params.id)
        .single();
    
      if (error) throw error;
    
      // Ensure placement is a string
      if (Array.isArray(data.placement)) {
        data.placement = JSON.stringify(data.placement);
      }
    
      return { data };
    }
    let query = supabase
      .from(resource)
      .select()
      .eq('id', params.id)
      .single();

      if (resource === 'lotteries') {
        const { data, error } = await supabase
          .from(resource)
          .select('*, lottery_schedules(*)')
          .eq('id', params.id)
          .single();
      
        if (error) throw error;
      
        // Transform weekdays for each schedule
        data.lottery_schedules = data.lottery_schedules.map(schedule => ({
          ...schedule,
          weekdays: schedule.weekdays.reduce((acc, day) => {
            acc[day] = true;
            return acc;
          }, {})
        }));
      
        return { data };
      }
    const { data, error } = await query;
  
    if (error) throw error;
  
    if (resource === 'withdrawals') {
      const ledgerSummary = await fetchLedgerSummary(data.user_id);
      return { data: { ...data, ledgerSummary } };
    }
  
    return { data };
  },

  update: async (resource, params) => {
    if (resource === 'lotteries') {
      const { lottery_schedules, ...lotteryData } = params.data;
      const { data: lottery, error: lotteryError } = await supabase
        .from(resource)
        .update(lotteryData)
        .eq('id', params.id)
        .select()
        .single();

      if (lotteryError) throw lotteryError;

      if (lottery_schedules && lottery_schedules.length > 0) {
        // Delete existing schedules
        await supabase
          .from('lottery_schedules')
          .delete()
          .eq('lottery_id', params.id);

        const schedulesWithLotteryId = lottery_schedules.map(schedule => ({
          ...schedule,
          lottery_id: lottery.id,
          weekdays: Object.entries(schedule.weekdays)
            .filter(([, value]) => value)
            .map(([key]) => parseInt(key)),
        }));

        const { data: schedules, error: schedulesError } = await supabase
          .from('lottery_schedules')
          .insert(schedulesWithLotteryId)
          .select();

        if (schedulesError) throw schedulesError;

        return { data: { ...lottery, lottery_schedules: schedules } };
      }

      return { data: lottery };
    }

    const { data, error } = await supabase
      .from(resource)
      .update(params.data)
      .eq('id', params.id)
      .select();

    if (error) throw error;

    return { data: data[0] };
  },
  
  create: async (resource, params) => {
    if (resource === 'lotteries') {
      const { lottery_schedules, ...lotteryData } = params.data;
      const { data: lottery, error: lotteryError } = await supabase
        .from(resource)
        .insert(lotteryData)
        .select()
        .single();

      if (lotteryError) throw lotteryError;

      if (lottery_schedules && lottery_schedules.length > 0) {
        const schedulesWithLotteryId = lottery_schedules.map(schedule => ({
          ...schedule,
          lottery_id: lottery.id,
          weekdays: Object.entries(schedule.weekdays)
          .filter(([, value]) => value)
          .map(([key]) => parseInt(key)),
        }));

        const { data: schedules, error: schedulesError } = await supabase
          .from('lottery_schedules')
          .insert(schedulesWithLotteryId)
          .select();

        if (schedulesError) throw schedulesError;

        return { data: { ...lottery, lottery_schedules: schedules } };
      }

      return { data: lottery };
    }

    const { data, error } = await supabase
      .from(resource)
      .insert(params.data)
      .select();

    if (error) throw error;

    return { data: data[0] };
  },

  delete: async (resource, params) => {
    const { data, error } = await supabase
      .from(resource)
      .delete()
      .eq('id', params.id)
      .select();

    if (error) throw error;

    return { data: data[0] };
  },

  deleteMany: async (resource, params) => {
    const { data, error } = await supabase
      .from(resource)
      .delete()
      .in('id', params.ids)
      .select();

    if (error) throw error;

    return { data };
  },

  getMany: async (resource, params) => {
    const { data, error } = await supabase
      .from(resource)
      .select()
      .in('id', params.ids);
  
    if (error) throw error;
  
    if (resource === 'withdrawals') {
      const dataWithPhone = await Promise.all(data.map(async (item) => ({
        ...item,
        user_phone: await fetchUserPhone(item.user_id),
      })));
  
      return { data: dataWithPhone };
    }
  
    return { data };
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    
    const { data, error, count } = await supabase
      .from(resource)
      .select('*', { count: 'exact' })
      .eq(params.target, params.id)
      .order(field, { ascending: order === 'ASC' })
      .range((page - 1) * perPage, page * perPage - 1);

    if (error) throw error;

    if (resource === 'withdrawals') {
      const dataWithPhone = await Promise.all(data.map(async (item) => ({
        ...item,
        user_phone: await fetchUserPhone(item.user_id),
      })));

      return {
        data: dataWithPhone,
        total: count,
      };
    }

    return {
      data,
      total: count,
    };
  },
};

export default supabaseDataProvider;