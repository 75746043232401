// src/components/Menu.js
import React from 'react';
import { Menu as RaMenu, MenuItemLink } from 'react-admin';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MoneyIcon from '@mui/icons-material/Money';
import LotteryIcon from '@mui/icons-material/Casino';
import ResultsIcon from '@mui/icons-material/Poll';

export const Menu = (props) => (
  <RaMenu {...props}>
    <MenuItemLink to="/" primaryText="Dashboard" leftIcon={<DashboardIcon />} />
    <MenuItemLink to="/withdrawals" primaryText="Withdrawals" leftIcon={<MoneyIcon />} />
    <MenuItemLink to="/lotteries" primaryText="Lotteries" leftIcon={<LotteryIcon />} />
    <MenuItemLink to="/lottery_results" primaryText="Lottery Results" leftIcon={<ResultsIcon />} />
  </RaMenu>
);